import './App.scss';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import {ConfigProvider} from "./context/ConfigContext";
import Home from "./pages/Home";
import Sports from "./pages/sport/Sports";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import jetTheme from "./components/themes/JetTheme";
import AboutUs from "./pages/AboutUs";
import Corporate from "./pages/Corporate";
import Incentive from "./pages/Incentive";
import TravelWithUs from "./pages/TravelWithUs";
import Sustainability from "./pages/Sustainability";
import CSR from "./pages/CSR";
import Blog from "./pages/blog/Blog";
import BlogPost from "./pages/blog/BlogPost";
import BlogCategory from "./pages/blog/BlogCategory";
import SkiRegions from "./pages/ski/SkiRegions";
import SkiPlaces from "./pages/ski/SkiPlaces";
import SkiAccommodations from "./pages/ski/SkiAccommodations";
import Employees from "./pages/Employees";
import Sport from "./pages/sport/Sport";
import Impressum from "./pages/mandatory/Impressum";
import PrivacyPolicy from "./pages/mandatory/PrivacyPolicy";
import Complaints from "./pages/mandatory/Complaints";
import ASZF from "./pages/mandatory/ASZF";
import TravelPolicy from "./pages/mandatory/TravelPolicy";
import GDPR from "./pages/mandatory/GDPR";
import WhyJet from "./pages/WhyJet";
import EU from "./pages/mandatory/EU";
import Branch from "./pages/Branch";
import SportCard from "./pages/sport/SportCard";


const App = () => {

    return <ThemeProvider theme={jetTheme}>
        <ConfigProvider>
            <Router>
                <Switch>
                    <Route path="/" component={Home} exact/>
                    <Route path="/sport" component={Sports} exact/>
                    <Route path="/sport/:id" component={Sport} exact/>
                    <Route path="/sport/:sportId/cards/:id" component={SportCard} exact/>
                    <Route path="/contact" component={Contact} exact/>
                    <Route path="/about-us" component={AboutUs} exact/>
                    <Route path="/why-jettravel" component={WhyJet} exact/>
                    <Route path="/eu" component={EU} exact/>
                    <Route path="/corporate" component={Corporate} exact/>
                    <Route path="/incentive" component={Incentive} exact/>
                    <Route path="/colleagues" component={Employees} exact/>
                    <Route path="/colleagues/:id" component={Branch} exact/>
                    {/*<Route path="/travel-with-us" component={TravelWithUs} exact/>*/}
                    <Route path="/sustainability" component={Sustainability} exact/>
                    <Route path="/csr" component={CSR} exact/>
                    <Route path="/blog" component={Blog} exact/>
                    <Route path="/blog-category/:category/:id" component={BlogPost} exact/>
                    <Route path="/blog-category/:id" component={BlogCategory} exact/>
                    <Route path="/ski-regions" component={SkiRegions} exact/>
                    <Route path="/ski-region/:id" component={SkiPlaces} exact/>
                    <Route path="/ski-region/:region/:id" component={SkiAccommodations} exact/>

                    <Route path="/impressum" component={Impressum} exact/>
                    <Route path="/privacy-policy" component={PrivacyPolicy} exact/>
                    <Route path="/complaints" component={Complaints} exact/>
                    <Route path="/aszf" component={ASZF} exact/>
                    <Route path="/travel-policy" component={TravelPolicy} exact/>
                    <Route path="/gdpr" component={GDPR} exact/>

                    <Route component={NotFound}/>
                </Switch>
            </Router>
        </ConfigProvider>
    </ThemeProvider>
}

export default App;
