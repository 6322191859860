import {Box, Button, Container, Grid, MenuItem, Stack, Typography} from "@mui/material";
import useScrollToTop from "../../hooks/useScrollToTop";
import HeroImage from "../../components/HeroImage";
import Footer from "../../components/Footer";
import useCallDataApi from "../../hooks/data";
import {useEffect, useRef, useState, useContext} from "react";
import Loading from "../../components/Loading";
import {useHistory, useParams} from "react-router-dom";
import ImageGallery from "../../components/ImageGallery";
import ErrorHandlingForm from "../../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../../components/formValidation/CustomTextField";
import CustomSelect from "../../components/formValidation/CustomSelect";
import {
    validateEmail,
    validateNonNegativeNumber,
    validateNumber,
    validateRequired
} from "../../components/formValidation/validators";
import {useSnackbar} from "notistack";
import {thousandSeparator} from "../../utils/utils";
import DrawerAppbar from "../../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import ConfigContext from "../../context/ConfigContext";
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import logo from '../../assets/shared/jet-sport-logo-white-01.png'
import ImageStack from "../../components/ImageStack";
import CustomDialog from "../../components/CustomDialog";



const Sport = () => {

    useScrollToTop()
    const {t} = useTranslation()
    const {language} = useContext(ConfigContext)
    const history = useHistory()
    const {getData: fetchSport, getFile} = useCallDataApi('sport')
    const {postData: postSportInquiry} = useCallDataApi('sport-inquiry')
    const [sport, setSport] = useState({})
    const [loading, setLoading] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const formRef = useRef(null)
    const contactRef = useRef(null)
    const {enqueueSnackbar} = useSnackbar()
    const initialState = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        comment: '',
        number_of_guests: '',
        room: '',
        programs: [],
    }
    const [formData, setFormData] = useState(initialState);

    const [errors, setErrors] = useState({
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
        comment: false,
        number_of_guests: false,
        room: false,
        programs: false,
    });

    const validators = {
        first_name: [validateRequired],
        last_name: [validateRequired],
        number_of_guests: [validateRequired, validateNumber, validateNonNegativeNumber],
        phone: [validateRequired],
        email: [validateRequired, validateEmail],
        comment: [],
        room: [validateRequired],
        programs: [],
    };
    const params = useParams()

    useEffect(() => {
        setLoading(true)
        fetchSport(`${params?.id}/`).then(r => {
            setSport(r)
        }).finally(() => setLoading(false))
    }, [])

    document.title = `Jet Travel | ${sport?.translations?.[language]?.name}`

    const calculatePrice = () => {
        const numberOfGuests = formData?.number_of_guests || 1
        const extras = sport?.fees?.reduce((sum, fee) => sum + fee?.price, 0) ?? 0
        const programs = formData?.programs?.reduce((sum, program) => sum + sport?.programs?.find(p => p?.id === program)?.price, 0) ?? 0
        const roomPrice = formData?.room ? sport?.rooms?.find(room => room?.id === formData?.room)?.price_per_person : 0
        return thousandSeparator(numberOfGuests * (extras + programs + roomPrice))
    }


    return <DrawerAppbar fade>
        <HeroImage logo={logo} description={`${sport?.from_date} - ${sport?.end_date}`} title={sport?.translations?.[language]?.name} underlineColor='#ff0000' image={sport?.image}/>
        <div style={{marginBottom: '64px'}}>

            <Box sx={{margin: 3, display: 'flex', gap: 2, flexWrap: 'wrap'}}>
                <Button onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
                {sport?.prospect && <Button endIcon={<DownloadForOfflineRoundedIcon/>} variant='outlined' onClick={() => getFile(sport?.prospect, 'pdf', sport?.translations?.[language]?.name)}>{t('sport.downloadProspect')}</Button>}
                <Button variant="outlined" onClick={() => setDialogOpen(true)}>{t('sport.signUp')}</Button>
                <Button variant="outlined" onClick={() => history.push('/contact')}>{t('general.contactUs')}</Button>
            </Box>
            <Container>

                <div dangerouslySetInnerHTML={{ __html: sport?.translations?.[language]?.description }}/>
                {sport?.images?.length > 0 && <Typography variant="h2" gutterBottom textAlign="center">
                    {t('sport.inPictures')}
                </Typography>}
                <ImageGallery images={sport?.images?.map(i => ({
                    src: i?.image,
                    alt: `${i?.id} image`
                }))}/>
            </Container>
            {sport?.cards && <ImageStack images={sport?.cards?.map(c => ({
                image: c?.image,
                title: c?.translations?.[language]?.name,
                link: `/sport/${params?.id}/cards/${c?.id}`,
                description: c?.translations?.[language]?.summary
            }))}/>}

            <CustomDialog
                open={dialogOpen}
                title={t('general.getQuote')}
                handleClose={() => setDialogOpen(false)}
                actions={<Stack spacing={2} direction='row'>
                    <Button variant='outlined' onClick={() => {
                        setDialogOpen(false)
                    }}>{t('general.cancel')}</Button>
                    <Button variant='contained' onClick={() => {
                        const data = contactRef.current.getData();
                        if (data) {
                            setLoading(true)
                            postSportInquiry('', {...data, sport: params?.id})
                                .then(() => {
                                    enqueueSnackbar(t('general.success'), {variant: 'success'})
                                    setFormData(initialState)
                                })
                                .finally(() => setLoading(false))
                        }
                    }}>{t('general.send')}</Button>
                </Stack>}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} sx={{ mx: 'auto' }}>
                        <ErrorHandlingForm
                            data={formData}
                            validators={validators}
                            setErrors={setErrors}
                            errors={errors}
                            setData={setFormData}
                            reference={contactRef}
                        >
                            <Stack spacing={2}>
                                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{width: '100%'}}>
                                    <Box sx={{width: '100%'}}>
                                        <Typography variant="body1" gutterBottom>
                                            {t('general.lastName')}
                                        </Typography>
                                        <CustomTextField
                                            name='last_name'
                                            data={formData}
                                            setData={setFormData}
                                            errors={errors}
                                            setErrors={setErrors}
                                            validators={validators}
                                            sx={{width: '100%'}}
                                        />
                                    </Box>
                                    <Box sx={{width: '100%'}}>
                                        <Typography variant="body1" gutterBottom>
                                            {t('general.firstName')}
                                        </Typography>
                                        <CustomTextField
                                            name='first_name'
                                            data={formData}
                                            setData={setFormData}
                                            errors={errors}
                                            setErrors={setErrors}
                                            validators={validators}
                                            sx={{width: '100%'}}
                                        />
                                    </Box>
                                </Stack>
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        {t('general.phone')}
                                    </Typography>
                                    <CustomTextField
                                        name='phone'
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        sx={{width: '100%'}}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        {t('general.email')}
                                    </Typography>
                                    <CustomTextField
                                        name='email'
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        sx={{width: '100%'}}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        {t('sport.numberOfGuests')}
                                    </Typography>
                                    <CustomTextField
                                        name='number_of_guests'
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        sx={{width: '100%'}}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        {t('sport.room')}
                                    </Typography>
                                    <CustomSelect
                                        name='room'
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        sx={{width: '100%'}}
                                    >
                                        {sport?.rooms?.map(r => <MenuItem key={r?.id} value={r?.id}>{`${r?.translations?.[language]?.name} - ${thousandSeparator(r?.price_per_person)} Ft / Fő`}</MenuItem>)}
                                    </CustomSelect>
                                </Box>
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        {t('sport.optionalPrograms')}
                                    </Typography>
                                    <CustomSelect
                                        name='programs'
                                        multiple
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        sx={{width: '100%'}}
                                    >
                                        {sport?.programs?.map(r => <MenuItem key={r?.id} value={r?.id}>{`${r?.translations?.[language]?.name} - ${thousandSeparator(r?.price)} Ft / Fő`}</MenuItem>)}
                                    </CustomSelect>
                                </Box>
                                <Box>
                                    <Typography variant="h6" gutterBottom>
                                        {t('sport.mandatoryFees')}
                                    </Typography>

                                    {sport?.fees?.map(fee => <Typography key={fee?.id} variant="body1" gutterBottom>
                                        {fee?.translations?.[language]?.name} - {thousandSeparator(fee?.price)} Ft
                                    </Typography>)}
                                </Box>
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        {t('general.comment')}
                                    </Typography>
                                    <CustomTextField
                                        name='comment'
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        sx={{width: '100%'}}
                                        multiline
                                        rows={3}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant="h5" gutterBottom>
                                        {t('general.sum')}: {calculatePrice()} Ft
                                    </Typography>
                                </Box>
                            </Stack>
                        </ErrorHandlingForm>

                    </Grid>
                </Grid>
            </CustomDialog>
        </div>
        <Footer />

        <Loading isLoading={loading}/>
    </DrawerAppbar>
}

export default Sport