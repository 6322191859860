import Footer from "../components/Footer";
import {Box, Button, Container, Typography} from "@mui/material";
import HeroImage from "../components/HeroImage";
import useScrollToTop from "../hooks/useScrollToTop";
import ImageStack from "../components/ImageStack";
import DrawerAppbar from "../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import mainImage from '../assets/aboutUs/mainImage.jpeg'
import firstCard from '../assets/aboutUs/Miert_JET_Travel.jpg'
import secondCard from '../assets/csapatunk.jpg'
import thirdCard from '../assets/aboutUs/EU_courses.jpg'
import fourthCard from '../assets/aboutUs/partners.jpg'
import fifthCard from '../assets/CSR/CSR_mainImage.jpg'

import CountUpGrid from "../components/CountUpGrid";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {useHistory} from "react-router-dom";

const AboutUs = () => {
    useScrollToTop()
    const {t} = useTranslation()
    const history = useHistory()

    document.title = `Jet Travel | ${t('aboutUs.title')}`

    const cards = [
        {
            image: firstCard,
            title: t('aboutUs.firstCard'),
            link: '/why-jettravel',
            description: t('aboutUs.firstCardDescription')
        },
        {
            image: secondCard,
            title: t('aboutUs.secondCard'),
            description: t('aboutUs.secondCardDescription'),
            link: '/colleagues'
        },
        {
            image: thirdCard,
            title: t('aboutUs.thirdCard'),
            description: t('aboutUs.thirdCardDescription'),
            link: '/eu'
        },
        {
            image: fourthCard,
            title: t('aboutUs.fourthCard'),
            description: t('aboutUs.fourthCardDescription')
        },
        {
            image: fifthCard,
            title: t('csr.title'),
            description: t('csr.description'),
            link: '/csr'
        }
    ]

    const numbers = [
        {number: 35, unit: t('corporate.firstNumber'), description: t('corporate.firstNumberDescription')},
        {number: 350, unit: t('corporate.secondNumber'), description: t('corporate.secondNumberDescription')},
        {reversed: true, number: 5, unit: t('corporate.thirdNumber'), description: t('corporate.thirdNumberDescription')},
        {number: 3, unit: t('corporate.fourthNumber'), description: t('corporate.fourthNumberDescription')},
        {number: 48, unit: t('home.fifthNumber'), description: t('home.fifthNumberDescription')},
        {number: 100, unit: t('home.sixthNumber'), description: t('home.sixthNumberDescription')},
        {number: 34000, unit: t('home.seventhNumber'), description: t('home.seventhNumberDescription')},
        {number: 150, unit: t('home.eighthNumber'), description: t('home.eightNumberDescription')},
        {number: 40, unit: t('home.ninethNumber'), description: t('home.ninethNumberDescription')},
        {number: 30, unit: t('home.tenthNumber'), description: t('home.tenthNumberDescription')},
    ]


    return <DrawerAppbar fade>
        <HeroImage description={t('aboutUs.description')} title={t('aboutUs.title')} underlineColor='#ff0000' image={mainImage}/>

        <div>
            <Box sx={{margin: 3}}>
                <Button  onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
            </Box>

            <Container sx={{marginTop: 2, marginBottom: 10}}>
                <Typography variant='h2' gutterBottom textAlign='center'>{t('aboutUs.headline')}</Typography>
                <Typography variant='body1' gutterBottom textAlign='center'>{t('aboutUs.description2')}</Typography>
            </Container>

            <ImageStack images={cards}/>

            <Container sx={{marginTop: 10, marginBottom: 10}}>
                <Typography variant="h2" gutterBottom>
                    {t('home.in_numbers')}
                </Typography>
                <CountUpGrid numbers={numbers} />
            </Container>

        </div>
        <Footer />
    </DrawerAppbar>
}

export default AboutUs