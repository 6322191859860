import TransparentAppBar from "../../components/TransparentAppbar";
import HeroImage from "../../components/HeroImage";
import {Box, Button, Grid, Typography} from "@mui/material";
import Footer from "../../components/Footer";
import useScrollToTop from "../../hooks/useScrollToTop";
import useCallDataApi from "../../hooks/data";
import {useContext, useEffect, useState} from "react";
import Loading from "../../components/Loading";
import {useHistory, useParams} from "react-router-dom";
import ImageCard from "../../components/ImageCard";
import {repairImageUrl} from "../../utils/utils";
import ConfigContext from "../../context/ConfigContext";
import DrawerAppbar from "../../components/DrawerAppbar";
import logo from '../../assets/shared/jet-adventure-logo-white-01.png'
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {useTranslation} from "react-i18next";
import ImageStack from "../../components/ImageStack";

const SkiPlaces = () => {

    useScrollToTop()
    const {getData: fetchSkiRegion} = useCallDataApi('ski-region')
    const {postData: fetchSkiPlaces} = useCallDataApi('ski-place')
    const [skiRegion, setSkiRegion] = useState({})
    const [skiPlaces, setSkiPlaces] = useState([])
    const [loading, setLoading] = useState(false)
    const params = useParams()
    const {baseUrl, language} = useContext(ConfigContext)
    const history = useHistory()
    const {t} = useTranslation()

    useEffect(() => {
        setLoading(true)
        fetchSkiRegion(params?.id).then(r => {
            setSkiRegion(r)
            document.title = `Jet Travel | ${r?.translations?.[language]?.name}`
            fetchSkiPlaces('for_region/', {region_id: params?.id}).then(p => setSkiPlaces(p))
        }).finally(() => setLoading(false))
    }, [])


    return <DrawerAppbar fade>
        <HeroImage logo={logo} title={skiRegion?.translations?.[language]?.name} underlineColor='#ff0000' image={skiRegion?.image}/>
        <div style={{marginTop: '64px', marginBottom: '64px'}}>
            <Box sx={{margin: 3}}>
                <Button sx={{marginBottom: 2}} onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
            </Box>
            <Typography variant='h2' gutterBottom textAlign='center'>{skiRegion?.translations?.[language]?.name}</Typography>


            <ImageStack images={skiPlaces?.map(c => ({
                image: repairImageUrl(c.image, baseUrl),
                title: c?.translations?.[language]?.name,
                link: `/ski-region/${params?.id}/${c?.id}`,
                description: c?.translations?.[language]?.description
            }))}/>


        </div>
        <Footer />

        <Loading isLoading={loading}/>
    </DrawerAppbar>
}

export default SkiPlaces