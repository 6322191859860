import {
    Stack,
    Typography,
    Box,
    Container,
    Button,
    Grid,
    IconButton,
    FormControlLabel,
    Checkbox,
    MenuItem
} from "@mui/material";
import Footer from "../components/Footer";
import ErrorHandlingForm from "../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../components/formValidation/CustomTextField";
import { useRef, useState } from "react";
import { validateRequired } from "../components/formValidation/validators";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import Loading from "../components/Loading";
import useCallDataApi from "../hooks/data";
import {useSnackbar} from "notistack";
import useScrollToTop from "../hooks/useScrollToTop";
import DrawerAppbar from "../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {useHistory} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import CustomSelect from "../components/formValidation/CustomSelect";

const Contact = () => {
    const {t} = useTranslation()
    document.title = `Jet Travel | ${t('contact.contact')}`
    useScrollToTop()
    const history = useHistory()
    const {postData: postContact} = useCallDataApi('contact')
    const {enqueueSnackbar} = useSnackbar();
    const [accept, setAccept] = useState(false)
    const [loading, setLoading] = useState(false)
    const [captchaToken, setCaptchaToken] = useState(null);

    const onCaptchaChange = (token) => {
        setCaptchaToken(token);
    };

    const initialState = {
        name: '',
        phone: '',
        email: '',
        comment: '',
        branch: '',
        callback: false,
    }
    const [formData, setFormData] = useState(initialState);

    const [errors, setErrors] = useState({
        name: false,
        phone: false,
        email: false,
        comment: false,
        branch: false,
        callback: false,
    });

    const validators = {
        name: [validateRequired],
        phone: [],
        email: [validateRequired],
        comment: [validateRequired],
        branch: [validateRequired],
        callback: [],
    };

    const contactRef = useRef(null);

    return (
        <DrawerAppbar>
            <Box sx={{ marginTop: '128px', marginBottom: '64px' }}>
                <Box sx={{margin: 3}}>
                    <Button sx={{marginBottom: 2}} onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
                </Box>

                <Container>
                    <Typography variant="h2" component="h1" gutterBottom textAlign='center'>
                        {t('contact.contact')}
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} sx={{ mx: 'auto' }}>
                            <ErrorHandlingForm
                                data={formData}
                                validators={validators}
                                setErrors={setErrors}
                                errors={errors}
                                setData={setFormData}
                                reference={contactRef}
                            >
                                <Stack spacing={2}>
                                    <Box>
                                        <Typography variant="body1" gutterBottom>
                                            {t('contact.name')}
                                        </Typography>
                                        <CustomTextField
                                            name='name'
                                            data={formData}
                                            setData={setFormData}
                                            errors={errors}
                                            setErrors={setErrors}
                                            validators={validators}
                                            sx={{width: '100%'}}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" gutterBottom>
                                            {t('contact.phone')}
                                        </Typography>
                                        <CustomTextField
                                            name='phone'
                                            data={formData}
                                            setData={setFormData}
                                            errors={errors}
                                            setErrors={setErrors}
                                            validators={validators}
                                            sx={{width: '100%'}}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" gutterBottom>
                                            {t('contact.email')}
                                        </Typography>
                                        <CustomTextField
                                            name='email'
                                            data={formData}
                                            setData={setFormData}
                                            errors={errors}
                                            setErrors={setErrors}
                                            validators={validators}
                                            sx={{width: '100%'}}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" gutterBottom>
                                            {t('contact.comment')}
                                        </Typography>
                                        <CustomTextField
                                            name='comment'
                                            data={formData}
                                            setData={setFormData}
                                            errors={errors}
                                            setErrors={setErrors}
                                            validators={validators}
                                            sx={{width: '100%'}}
                                            multiline
                                            rows={3}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" gutterBottom>
                                            {t('contact.branch')}
                                        </Typography>
                                        <CustomSelect
                                            name="branch"
                                            data={formData}
                                            setData={setFormData}
                                            errors={errors}
                                            setErrors={setErrors}
                                            validators={validators}
                                            sx={{width: '100%'}}
                                        >
                                            <MenuItem value='sales'>{t('contact.sales')}</MenuItem>
                                            <MenuItem value='corporate'>{t('contact.corporate')}</MenuItem>
                                            <MenuItem value='outgoing'>{t('contact.outgoing')}</MenuItem>
                                            <MenuItem value='domestic'>{t('contact.domestic')}</MenuItem>
                                            <MenuItem value='sport'>{t('contact.sport')}</MenuItem>

                                        </CustomSelect>
                                    </Box>
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formData.callback}
                                                    onChange={(e) => setFormData({...formData, callback: e.target.checked})}
                                                    color="primary"
                                                />
                                            }
                                            label={t('contact.callback')}  // Use translation for the privacy policy label
                                        />
                                    </Box>
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={accept}
                                                    onChange={(e) => setAccept(e.target.checked)}
                                                    color="primary"
                                                />
                                            }
                                            label={t('contact.privacyPolicy')}  // Use translation for the privacy policy label
                                        />
                                        {errors.privacy_policy && (
                                            <Typography variant="body2" color="error">
                                                {t('general.required')}
                                            </Typography>
                                        )}
                                    </Box>
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                                        onChange={onCaptchaChange}
                                    />
                                    <Button variant='contained' disabled={!accept} onClick={() => {
                                        const data = contactRef.current.getData();
                                        if (data) {
                                            setLoading(true)
                                            postContact('', {...data, captcha_token: captchaToken})
                                                .then(() => {
                                                    enqueueSnackbar(t('general.success'), {variant: 'success'})
                                                    setFormData(initialState)
                                                })
                                                .finally(() => setLoading(false))
                                        }

                                    }}>{t('general.send')}</Button>
                                </Stack>
                            </ErrorHandlingForm>

                        </Grid>
                    </Grid>
                    <Box mt={4}>
                        <Typography variant="body1" gutterBottom>
                            {t('contact.contactText')}
                        </Typography>
                    </Box>
                    <Box mt={4} display="flex" justifyContent="space-around">
                        <Box textAlign="center">
                            <IconButton color="primary">
                                <LocationOnIcon fontSize="large" />
                            </IconButton>
                            <Typography variant="body1" gutterBottom>
                                H-1113 Budapest, Bocskai út 77-79
                            </Typography>
                        </Box>
                        <Box textAlign="center">
                            <IconButton color="primary">
                                <EmailIcon fontSize="large" />
                            </IconButton>
                            <Typography variant="body1" gutterBottom>
                                jettravel@jettravel.hu
                            </Typography>
                        </Box>
                        <Box textAlign="center">
                            <IconButton color="primary">
                                <PhoneIcon fontSize="large" />
                            </IconButton>
                            <Typography variant="body1" gutterBottom>
                                +36 1 209 2110
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Footer />

            <Loading isLoading={loading}/>
        </DrawerAppbar>
    );
}

export default Contact;
