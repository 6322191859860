import {Box, Button, Container, Stack, Typography} from "@mui/material";
import Footer from "../components/Footer";
import HeroSection from "../components/Hero";
import CountUpGrid from "../components/CountUpGrid";
import LogoScroller from "../components/LogoScroller";
import useScrollToTop from "../hooks/useScrollToTop";
import {useContext, useEffect, useState} from "react";
import useCallDataApi from "../hooks/data";
import Loading from "../components/Loading";
import ImageStack from "../components/ImageStack";
import DrawerAppbar from "../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import corporateImage from '../assets/home/corporate.jpg'
import incentive from '../assets/incentive/main.jpeg'
import travelWithUs from '../assets/travelWithUs.jpeg'
import seaTravel from '../assets/seaTravel.jpeg'
import sport from '../assets/Sportutak.jpeg'
import ski from '../assets/ski.jpeg'
import aboutUs from '../assets/aboutUs/mainImage.jpeg'
import CustomDialog from "../components/CustomDialog";
import {repairImageUrl} from "../utils/utils";
import ConfigContext from "../context/ConfigContext";
import {useHistory} from "react-router-dom";

const Home = () => {
    document.title = 'Jet Travel'
    useScrollToTop()
    const [loading, setLoading] = useState(false)
    const [popup, setPopup] = useState({})
    const [references, setReferences] = useState([])
    const {getData: fetchReferences} = useCallDataApi('reference')
    const {getData: fetchPopup} = useCallDataApi('popup')
    const {t} = useTranslation()
    const {language, baseUrl} = useContext(ConfigContext)
    const history = useHistory()

    const cards = [
        {
            image: aboutUs,
            title: t('aboutUs.title'),
            link: '/about-us',
            description: t('aboutUs.description')
        },
        {
            image: corporateImage,
            title: t('home.corporateTitle'),
            link: '/corporate',
            description: t('home.corporateDescription')
        },
        {
            image: incentive,
            title: t('home.incentiveHeadline'),
            link: '/incentive',
            description: t('home.incentiveDescription'),
        },
        {
            image: seaTravel,
            title: t('home.seaTravelTitle'),
            link: 'https://jetcruise.hu',
            description: t('home.seaTravelDescription'),
        },
        {
            image: travelWithUs,
            title: t('home.travelWithUsTitle'),
            link: 'https://utazas.jettravel.hu/',
            description: t('home.travelWithUsDescription'),
        },
        {
            image: sport,
            title: t('home.sportTitle'),
            link: '/sport',
            description: t('home.sportDescription'),
        },
        {
            image: ski,
            title: t('home.skiHeadline'),
            link: '/ski-regions',
            description: t('home.skiDescription'),
        },
    ]


    const numbers = [
        {number: 35, unit: t('corporate.firstNumber'), description: t('corporate.firstNumberDescription')},
        {number: 350, unit: t('corporate.secondNumber'), description: t('corporate.secondNumberDescription')},
        {reversed: true, number: 5, unit: t('corporate.thirdNumber'), description: t('corporate.thirdNumberDescription')},
        {number: 3, unit: t('corporate.fourthNumber'), description: t('corporate.fourthNumberDescription')},
        {number: 48, unit: t('home.fifthNumber'), description: t('home.fifthNumberDescription')},
        {number: 100, unit: t('home.sixthNumber'), description: t('home.sixthNumberDescription')},
        {number: 34000, unit: t('home.seventhNumber'), description: t('home.seventhNumberDescription')},
        {number: 150, unit: t('home.eighthNumber'), description: t('home.eightNumberDescription')},
        {number: 40, unit: t('home.ninethNumber'), description: t('home.ninethNumberDescription')},
        {number: 30, unit: t('home.tenthNumber'), description: t('home.tenthNumberDescription')},
    ]

    useEffect(() => {
        setLoading(true);

        Promise.all([fetchReferences(), fetchPopup('get_popup')])
            .then(([references, popup]) => {
                setReferences(references);
                setPopup(popup);
                console.log(popup)
            })
            .finally(() => setLoading(false));
    }, []);


    return <DrawerAppbar fade={false}>

        <div style={{marginTop: '64px', marginBottom: '64px'}}>
            <HeroSection />

            <Container sx={{marginTop: 10, marginBottom: 10}}>
                <Typography textAlign='center' variant='h2' gutterBottom>{t('home.title')}</Typography>
                <Typography variant='body1' marginBottom={3}>{t('home.subtitle')}</Typography>
            </Container>

            <ImageStack images={cards}/>

            <Container sx={{marginTop: 10, marginBottom: 10}}>
                <Typography variant="h2" gutterBottom textAlign='center'>
                    {t('home.in_numbers')}
                </Typography>
                <CountUpGrid numbers={numbers} />
            </Container>

            <Container>
                <Typography variant="h2" gutterBottom textAlign='center'>
                    {t('home.refernces')}
                </Typography>
                <LogoScroller logos={references.map(r => r?.image)} />
            </Container>
        </div>
        <Footer />

        <CustomDialog open={Boolean(popup?.translations?.[language]?.name)} title={popup?.translations?.[language]?.name} handleClose={() => setPopup(false)}>
            <Stack spacing={1}>
                <Typography variant='body1'>{popup?.translations?.[language]?.text}</Typography>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img style={{width: '50%'}} src={repairImageUrl(popup?.image, baseUrl)} alt={popup?.translations?.[language]?.name}/>
                </Box>
                {popup?.link?.startsWith('http') ? <Button variant='outlined' onClick={() => window.open(popup?.link)}>{t('general.open')}</Button> : <Button variant='outlined' onClick={() => history.push(popup?.link)}>{t('general.open')}</Button>}
            </Stack>
        </CustomDialog>

        <Loading isLoading={loading}/>
    </DrawerAppbar>
}

export default Home